.navbar-buttons-list {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 1.6rem;
    position: relative;
    top: 6px;
    display: none;

    @media screen and (min-width:992px) and (max-width:1070px) {
        padding-right: 0.5rem;
        padding-left: 0.5rem;

    }

    @media screen and (min-width:1071px)and (max-width:1200px) {
        padding-right: 1rem;
        padding-left: 1rem;
        // padding: 0px;
    }

    @media screen and (min-width:992px) {
        display: flex !important;
    }
}

.navbar-each-list-btn {
    list-style-type: none;

    .navbar-button-cls {
        background-color: transparent !important;
        color: #666 !important;
        box-shadow: none !important;
        font-weight: 450 !important;
        // font-size: 1rem !important;
        text-transform: capitalize !important;
        min-width: 64px !important;

        &:hover {
            background-color: #F5F5F5 !important;
            border-radius: 4px !important;
        }

        [class*="MuiButton-endIcon"] {
            margin-left: 2px !important;
            margin-top: 4px;
            // margin-left: 4px !important;

        }

        [class*="MuiButton-startIcon"] {
            margin-right: 5px !important;
        }

        @media screen and (min-width:992px) and (max-width:1050px) {
            padding: 6px 9px !important;

        }

        @media screen and (min-width:1051px) and (max-width:1200px) {
            padding: 6px 11px !important;

        }

        @media screen and (max-width:991px) {
            width: 100% !important;
            justify-content: flex-start !important;
            padding-left: 1.6rem !important;
        }

        @media screen and (min-width:1051px) {
            font-size: 1rem !important;
        }

    }

    @media screen and (min-width:992px) {
        &:nth-child(3) {
            // margin-left: auto;
            display: block;
        }

        &:nth-child(4) {
            margin-left: auto;
            display: inline-block;
            box-sizing: border-box;
        }
    }

    @media screen and (max-width:991px) {
        width: 100%;
    }
}

.nav-side-drawer-container-cls {
    // transition: 0.5s !important;

    [class*="Drawer-content"] {
        max-width: 380px !important;
        // width: 100% !important;
        transition: 0.5s !important;
    }

    .right-side-drawer-content-cls {
        flex: none !important;
    }

    .list-subelement-subcontent-cls {
        display: flex !important;
        flex-direction: column !important;
        padding-top: 10px !important;
        padding-bottom: 3px !important;
    }

    .sidenav-sub-menu-items-container-cls {
        width: 100%;
        position: relative;
        top: -10px;
    }

    .drawer-title-cls {
        font-size: 1.6rem !important;
        color: #000000 !important;
        text-align: left !important;
        font-weight: 300 !important;
        border: none;
        // padding-top: 25px;
        padding-left: 20px;
        margin-bottom: 0;

        @media screen and (min-width:992px)and (max-width:1200px) {
            font-size: 1.4rem !important;

        }
    }

    .close-nav-menu {
        display: block;
        margin: 4px 7px 0 auto;
        width: 30px;
        cursor: pointer;
        color: #000000;
        font-size: 1.8rem !important;
        font-weight: 300 !important;
        border: none;
        background: none;

        @media screen and (max-width:768px) {
            font-size: 1.4rem !important;
        }
    }

    .side-drawer-list-item-cls {
        padding-left: 20px;

        &:hover {
            background-color: rgba(0, 0, 0, .04) !important;
        }
    }

    .side-drawer-list-item-btn-cls {
        color: #000000 !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    .sidenav-sub-menu-items {
        margin-left: 3px !important;
        padding-left: 20px !important;

        &:hover {
            background-color: rgba(0, 0, 0, .04) !important;
        }

    }

    .view-all-footer-text-cls {
        font-size: 1.1rem !important;
        margin-left: 20px;
        color: #000000 !important;
        text-decoration: none !important;

    }

    .icon-sprite-white {
        // background: url(https://trisept.widen.net/content/bked9agiow/png/CCV_ICON_NAV_icon_sprite-grey.png) no-repeat;
        background: url('../ai-img/CCV_ICON_NAV_icon_sprite-grey.png') no-repeat;
        margin-right: 3px;
    }

    .icon-sprite-white {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-bottom: -2px;
    }

    .bg-chat {
        background-position: -118px -190px;
    }

    .bg-email {
        background-position: -226px -190px;
    }

    .bg-phone {
        background-position: -118px -10px;
    }

    .bg-question {
        background-position: -46px -118px;
    }

    .bg-feedback {
        background-position: -10px -10px;
    }

    .bg-social_facebook {
        background-position: -154px -82px;
    }

    .bg-social_tiktok {
        background-position: -119px -226px;
    }

    .bg-social_instagram {
        background-position: -154px -118px;
    }

    .bg-social_pinterest {
        background-position: -10px -154px;
    }

    .bg-social_blog {
        background-position: -154px -46px;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}


// Mobile classes
.mobile-navbar-menu-list-container-cls {
    background-color: #fff;
    height: 100vh;
    // overflow-y: auto;
    width: 200px;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.5s ease !important;
    transform: translate(-100%);
    z-index: 999;
    box-sizing: border-box;

    &.active {
        transform: translate(0);
    }

    .mobile-navbar-menu-list {
        padding-left: 0px !important;
    }
    button#ot-sdk-btn{
        width: 100%;
        background: transparent;
        font-size: 16px !important;
        text-decoration: none;
        box-shadow: none !important;
        font-weight: 450 !important;
        position: absolute;
        bottom: 130px;
    }
    .cookie-center-mobile-cls {
        @media screen and (min-width:769px) {
            display: none !important;
        }
    }
    .mobile-close-nav-menu {
        display: block;
        margin: 8px 7px 0 auto;
        width: 24px;
        cursor: pointer;
        color: #000000;
        font-size: 1rem !important;
        font-weight: 300 !important;
        border: none;
        background: none;

        @media screen and (min-width:768px) and (max-width:991px) {
            font-size: 1.4rem !important;
        }
    }

    @media screen and (min-width:768px) and (max-width:991px) {
        width: 250px;
    }

    @media screen and (min-width:992px) {
        display: none;
    }
}